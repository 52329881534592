import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { formStates, resetForm } from '../../redux/reducers/joinClass.reducer';
import { initialiseInstance } from '../../redux/reducers/data/search.reducer';
import { postNotificationMessage } from '../../redux/actions/postMessage';
import { featureIsEnabled } from '../../globals/envSettings';
import JoinClassCodeInput from './JoinClassCodeInput';
import JoinClassCodeSubmitting from './JoinClassCodeSubmitting';
import JoinClassCodeConfirmation from './JoinClassCodeConfirmation';
import JoinClassPreview from './JoinClassPreview';
import withLocalizedContent from '../../language/withLocalizedContent';

class JoinClass extends React.Component {
  handleClose = () => {
    const { resetAction, closePanel, postMessageAction } = this.props;

    // sends post message when the class-joining user journey ends and the panel should be closed
    postMessageAction({ eventName: 'INTERACTION_COMPLETE', viewId: '*' });
    resetAction();
    closePanel();
  };

  handleOnComplete = () => {
    const {
      resetAction,
      onComplete,
      closePanel,
      postMessageAction,
      initialiseSearch,
      errorOccurred,
      userId,
      currentOrganisationId
    } = this.props;

    // sends post message when the class-joining user journey ends and the panel should be closed
    postMessageAction({ eventName: 'INTERACTION_COMPLETE', viewId: '*' });
    resetAction();
    closePanel();
    initialiseSearch('profileClasses', false, {
      active: true,
      orgId: currentOrganisationId,
      userId
    });

    if (!errorOccurred && !!onComplete) {
      onComplete();
    }
  };

  handleOnValidationError = () => {
    const { resetAction } = this.props;
    resetAction();
  };

  render() {
    const {
      errorOccurred,
      errorMessage,
      formState,
      classroomName,
      organisationName,
      groupType,
      localizedContent: { joinClass: content, joiningAndProductCodes },
      userRole
    } = this.props;
    let viewContent;
    const joiningAndProductCodesContent = featureIsEnabled('opt-main-features') ? joiningAndProductCodes : {};

    switch (formState) {
      case formStates.INPUTTING:
        viewContent = (
          <JoinClassCodeInput
            closePanel={this.handleClose}
            content={{
              ...content,
              ...joiningAndProductCodesContent,
              enter_a_code_text: content.enter_a_code_text,
              primary_button_text: content.primary_button_text,
              enter_a_code_placeholder: content.enter_a_code_placeholder
            }}
            userRole={userRole}
          />
        );
        break;
      case formStates.SUBMITTING:
        viewContent = <JoinClassCodeSubmitting content={content} />;
        break;
      case formStates.PREVIEW:
        viewContent = (
          <JoinClassPreview
            content={{ ...content, ...joiningAndProductCodesContent }}
            classroomName={classroomName}
            organisationName={organisationName}
            groupType={groupType}
            closePanel={this.handleClose}
          />
        );
        break;
      case formStates.CONFIRMATION:
        viewContent = (
          <JoinClassCodeConfirmation
            errorOccurred={errorOccurred}
            errorMessage={errorMessage}
            closePanel={this.handleClose}
            onComplete={this.handleOnComplete}
            onValidationError={this.handleOnValidationError}
            classroomName={classroomName}
            content={{ ...content, ...joiningAndProductCodesContent }}
          />
        );
        break;
      default:
        viewContent = (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }

    return <div>{viewContent}</div>;
  }
}

export default compose(
  withLocalizedContent('joinClass', 'joiningAndProductCodes'),
  connect(
    ({
      joinClass: { formState, classroomName, organisationName, groupType, errorOccurred, errorMessage },
      identity: { userId, currentOrganisationId, role }
    }) => ({
      formState,
      classroomName,
      organisationName,
      groupType,
      errorOccurred,
      errorMessage,
      userId,
      currentOrganisationId,
      userRole: role
    }),
    {
      resetAction: resetForm,
      postMessageAction: postNotificationMessage,
      initialiseSearch: initialiseInstance
    }
  )
)(JoinClass);

JoinClass.propTypes = {
  formState: PropTypes.string.isRequired,
  errorOccurred: PropTypes.bool,
  errorMessage: PropTypes.object,
  classroomName: PropTypes.string,
  organisationName: PropTypes.string,
  groupType: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  resetAction: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  postMessageAction: PropTypes.func,
  initialiseSearch: PropTypes.func,
  userId: PropTypes.string,
  currentOrganisationId: PropTypes.string,
  userRole: PropTypes.string
};
